var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.modalId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.canEdit()
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: { click: _vm.modalOk },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      class: { "mb-0": _vm.showNameError },
                      attrs: {
                        label: _vm.$t("task.field.name"),
                        "label-for": "name",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }",
                              },
                            ],
                            attrs: {
                              id: "name",
                              type: "text",
                              "data-vv-as": _vm.$t("task.field.name"),
                              "data-vv-name": "group.headerName",
                              "data-vv-delay": "500",
                              state: _vm.fieldValidateUtil.stateValidate(
                                false,
                                _vm.veeFields,
                                _vm.errors,
                                "group.headerName"
                              ),
                              autofocus: "",
                              trim: "",
                            },
                            model: {
                              value: _vm.group.headerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.group, "headerName", $$v)
                              },
                              expression: "group.headerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showNameError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("group.headerName")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      class: { "mb-0": _vm.showPropertyError },
                      attrs: { label: _vm.$t("task.group.field.property") },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("multiselect", {
                            staticClass:
                              "custom-dropdown-options enable-option-icon",
                            attrs: {
                              "max-height": 300,
                              options: _vm.propertyOpts.map((i) => i.value),
                              "custom-label": _vm.getPropertyOptionLabel,
                              placeholder: _vm.$t("task.group.select_property"),
                              searchable: false,
                              "allow-empty": false,
                              showLabels: false,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _vm.group.property == props.option
                                      ? _c("font-awesome-icon", {
                                          staticClass: "selected-option-icon",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "option__title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPropertyOptionLabel(
                                              props.option
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.group.property,
                              callback: function ($$v) {
                                _vm.$set(_vm.group, "property", $$v)
                              },
                              expression: "group.property",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showPropertyError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("group.property")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t(`task.group.field.tasks`))),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-action",
              attrs: { id: "BTN_ADD_TASK" },
              on: {
                click: function ($event) {
                  return _vm.openTaskNameModal(true)
                },
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
            1
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "BTN_ADD_TASK",
                triggers: "hover",
                placement: "top",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("task_compact.button.add_task")) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-action",
              attrs: { id: "BTN_ADD_DYNAMIC_TASK" },
              on: {
                click: function ($event) {
                  return _vm.addDynamicTasks(true)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "arrow-down-to-bracket"] },
              }),
            ],
            1
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "BTN_ADD_DYNAMIC_TASK",
                triggers: "hover",
                placement: "top",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("task_compact.button.add_dynamic_tasks")) +
                  " "
              ),
            ]
          ),
          _c("BadgeGroup", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item, index }) {
                  return [
                    _c("Badge", {
                      key: index,
                      attrs: {
                        text: item.name,
                        variant: "primary",
                        pillable: !!item.pillable,
                      },
                      on: {
                        badgeRemove: function ($event) {
                          return _vm.columnBadgeRemove(index)
                        },
                        badgeClick: function ($event) {
                          return _vm.columnBadgeEdit(index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.group.children,
              callback: function ($$v) {
                _vm.$set(_vm.group, "children", $$v)
              },
              expression: "group.children",
            },
          }),
          _c(
            "b-form-invalid-feedback",
            {
              staticClass: "alert-danger form-field-alert",
              class: { "d-block": _vm.showChildrenError },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.errors.first("group.children")) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.state.taskNameShow
        ? _c("TaskGroupTaskEdit", {
            attrs: {
              show: _vm.state.taskNameShow,
              name: _vm.taskName,
              color: _vm.taskColor,
              skills: _vm.taskSkills,
              staff: _vm.taskStaff,
              resources: _vm.taskResources,
              rebates: _vm.taskRebates,
              tags: _vm.taskTags,
              existingNames: _vm.existingTaskNames,
              isNew: _vm.taskNameEditIndex == null,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "taskNameShow", $event)
              },
              ok: _vm.taskNameOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }